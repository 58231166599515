<template>
  <v-card class="elevation-0">
    <v-card-title>
      {{ title }}
      <v-spacer></v-spacer>
        <v-text-field
            v-on:keyup.enter="searchOnEnter"
            v-on:blur="searchOnEnter"
            append-icon="mdi-magnify"
            hint="Kode, Deskripsi (press ENTER to search)"
            label="Search"
        ></v-text-field>
    </v-card-title>
    <v-data-table
        v-model="selectedItems"
        :single-select="!multiSelect"
        :show-select="multiSelect"
        :headers="headers"
        :items="fSectorTypesFiltered"
        :page.sync="currentPage"
        :items-per-page="pageSize"
        hide-default-footer
        class="elevation-0"
        @page-count="totalTablePages =totalPaginationPages "
    >
      <template v-slot:top >
        <v-row align="center" class="ml-4 mr-4">
          <v-switch
              v-model="multiSelect"
              :label="multiSelect?'Multi Select':'Single Select'"
              x-small
              class="pa-3"
          ></v-switch>
          <v-btn
              fab
              dark
              color="red accent-4"
              x-small
              :disabled="!multiSelect "
              class="mr-2"
              @click="deleteDialogMultiShow"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>

          <v-spacer></v-spacer>

          <v-menu offset-y style="align-items: start">
            <template v-slot:activator="{ on, attrs }" >
              <v-btn
                  class="mx-1"
                  fab
                  dark
                  x-small
                  color="blue"
                  v-bind="attrs"
                  v-on="on"
              >
                <v-icon>mdi-view-grid</v-icon>
              </v-btn>

            </template>
            <v-list color="grey lighten-4" class="mr" >
              <v-list-item>
                <v-btn
                    class="mx-1"
                    plain
                    elevation="0"
                    color="gray darken-1"
                    small
                    @click="translateDialogShow"
                    
                >
                  <v-icon color="red">mdi-translate</v-icon>
                  Translate to English
                </v-btn>
              </v-list-item>

            </v-list>

          </v-menu>

          <v-btn
              fab
              dark
              color="green"
              x-small
              @click="showDialogNew"
              :disabled="multiSelect"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-row>
      </template>


      <template v-slot:[`item.statusActive`]="{ item }">
        <v-chip
            :color="getColorStatusActive(item.statusActive)"
            dark
            small
        >
          {{ item.statusActive===true?'Aktif': '-' }}
        </v-chip>
      </template>


      <template v-slot:[`item.avatar`]="{ item}">
          <v-img
              :lazy-src="lookupImageUrlLazy(item)"
              :src="lookupImageUrl(item)"
              alt="avatar"
              width="70px"
              height="70px"
              class="ma-2 rounded"
          >
          </v-img>
      </template>

      <template v-slot:[`item.description`]="{ item }">
        <!-- DUAL BAHASA -->
        <div class="subtitle-1" v-if="lang==='id'">
          {{item.description}}
        </div>
        <div class="subtitle-1" v-if="lang==='en'">
          {{item.descriptionEn}}
        </div>
        <div class="subtitle-2 font-weight-light" v-if="item.kode1">
          *{{item.kode1}}
        </div>
      </template>

      <template v-slot:[`item.fdivisionBean`]="{ item }">
        <div class="fill-height">
          <div  class="text-caption small">
            {{ lookupFDivision(item.fdivisionBean) }}
          </div>
          <div>
            <v-chip
                :color="getColorStatusActive(item.statusActive)"
                dark
                small
            >
              {{ item.statusActive===true?'Aktif': '-' }}
            </v-chip>
          </div>
        </div>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
            @click="showDialogEdit(item)"
            icon
            :disabled="multiSelect"
        >
          <v-icon
              small
              color="warning"
          >
            mdi-pencil
          </v-icon>
        </v-btn>

        <v-btn
            @click="deleteDialogShow(item)"
            icon
            :disabled="multiSelect"
        >
          <v-icon
              small
              color="red accent-4"
          >
            mdi-delete
          </v-icon>
        </v-btn>
      </template>

    </v-data-table>

    <v-container>
      <v-row justify="end" align="center">
        <v-col
        cols="4"
        md="2"
        sm="2"
        >
          <v-select
              v-model="pageSize"
              :items="pageSizes"
              label="Items per page"
          ></v-select>
        </v-col>
        <v-col
            cols="10"
            md="9"
            sm="8"
        >
          <v-pagination
              v-model="currentPage"
              :length="totalPaginationPages"
              total-visible="8"
              circle
          ></v-pagination>
        </v-col>
      </v-row>
    </v-container>
    
    <DeleteConfirmDialog
        ref="refDeleteConfirmDialog"
        @eventFromDeleteConfirmDialog1="deleteItemConfirmedSingleSelect"
        @eventFromDeleteConfirmDialog2="deleteItemConfirmedMultiSelect"
    ></DeleteConfirmDialog>
    <!-- DUAL BAHASA -->
    <ConfirmDialog
        ref="refConfirmDialog"
        @eventFromOkeConfirmDialog="confirmDialogOke"
    ></ConfirmDialog>

    <FSectorTypeDialog :formMode.sync="formMode"
                  ref="refFormDialog"
                  @eventFromFormDialogNew="saveDataNew"
                  @eventFromFormDialogEdit="saveDataEdit"
    ></FSectorTypeDialog>

    <v-snackbar
        v-model="snackbar"
    >
      {{ snackBarMesage }}
      <template v-slot:action="{ attrs }">
        <v-btn
            text
            v-bind="attrs"
            @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog width="100px" v-model="dialogLoading" >
      <v-card class="px-4 py-7">
        <v-row justify="center">
          <v-col cols="12" align="center">
            <v-progress-circular
              :size="40"
              :width="5"
              v-if="loading"
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import FSectorTypeService from '../../../services/apiservices/f-sector-type-service';
import FDivisionService from "../../../services/apiservices/f-division-service";

import DeleteConfirmDialog from "../../utils/DeleteConfirmDialog";

import FSectorTypeDialog from "./FSectorTypeDialog";
import FormMode from "../../../models/form-mode";
import FSectorType from '../../../models/f-sector-type'
import FileService from "../../../services/apiservices/file-service";
//DUAL BAHASA
import ConfirmDialog from "@/components/utils/ConfirmDialog.vue";
import LangTranslatorService from "@/services/apiservices/lang-translator-service";

export default {
  components: {ConfirmDialog, FSectorTypeDialog, DeleteConfirmDialog },
  data () {
    return {

      title: 'SECTOR TYPE',
      snackbar: false,
      snackBarMesage: '',

      dialogLoading: false,
      loading: false,

      multiSelect: false,
      selectedItems: [],

      currentPage: 1,
      totalTablePages: 1,
      totalPaginationPages: 1,
      pageSize: 10,
      pageSizes: [10, 25, 50, 150, 500],

      search: '',
      headers: [
        {
          text: '',
          align: 'center',
          sortable: false,
          value: 'avatar',
        },
        { text: 'Deskripsi', value: 'description', width:"30%" },
        { text: 'Div (Kab/Kota)', value: 'fdivisionBean' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],

      formMode: '',
      itemSelectedIndex: -1,
      itemSelected: '',
      fSectorTypes:[
        new FSectorType(0, '','')
      ],
      itemsFDivision: [
        { id: 0, kode1: '', description: '' },
      ],
      itemsFSalesman:[],

    }
  },
  computed: {
    // DUAL BAHASA
    lang:{
      get(){
        return this.$store.state.langModule.lang
      },
      set(val) {
        this.$store.dispatch('langModule/setLang', val)
      }
    },
    currentUser(){
      return this.$store.state.auth.user;
    },
    fSectorTypesFiltered(){
      return this.fSectorTypes
    },

  },
  watch: {
    currentPage: {
      handler: function (value) {
        if (value) this.fetchFSectorType()
      }
    },
    pageSize: {
      handler: function (value) {
        const refreshData = (this.currentPage===1)
        this.currentPage =1
        if (refreshData) {
          console.log("Change PageSize " + value)
          this.fetchFSectorType()
        }
      }
    },
		// DUAL BAHASA
    lang: {
      handler: function (val, oldVal) {
        if (val !== oldVal){
          // this.fetchFSectorType()
        }
      }
    },

  },
  methods: {
    searchOnEnter(event){
      if (this.search !== event.target.value) { //Krusial untuk search
        // console.log(`${event.target.value} vs ${this.search}`)
        this.currentPage = 1
        this.search = event.target.value
        this.fetchFSectorType()
      }
    },
    fetchParent(){
      if (this.currentUser.organizationLevel === "DIV") {
        FDivisionService.getFDivisionById(this.currentUser.fdivisionBean).then(
            response=>{
              this.itemsFDivision = [response.data]
              // console.log(response.data.items)
            },
            error=>{
              console.log(error.response)
            }
        )
      }else {
        FDivisionService.getAllFDivision().then(
            response => {
              // console.log(JSON.stringify(response.data))
              this.itemsFDivision = response.data
            },
            error => {
              console.log(error.response)
            }
        )
      }

    },
    fetchFSectorType() {
      FSectorTypeService.getAllFSectorTypeContaining(this.currentPage, this.pageSize, "id", "DESC", this.search).then(
          response => {
            // console.log(response.data.items)
            const { items, totalPages} = response.data
            this.fSectorTypes = items
            this.totalPaginationPages = totalPages
            // console.log(`TotalPage ${totalPages} and TotalItems ${items} `)
          },
          error => {
            // console.log(error.response)
            if (error.response.status===401){
              this.snackBarMesage = "Anda tidak mempunyai Akses!!"
              this.snackbar = true
            }
          }
      )

    },
    linkNeated(link){
      return link.replace(/\s+/g, '-').toLocaleLowerCase()
    },
    // DUAL BAHASA
    translateDialogShow(){
      this.$refs.refConfirmDialog.showDialog("Yakin akan Membuat Terjemahan  Data Dalam Daftar? ", "(Yang belum mempunyai terjemahan)")
    },
    //DUAL BAHASA
    confirmDialogOke(){
      // console.log("hello")
      this.snackBarMesage = ''
      this.dialogLoading = true
      this.loading = true
      this.$refs.refConfirmDialog.setDialogState(false)
      let itemsFSectorTypeTranslate = []
      let itemsFSectorTypeSorted = []
      this.fSectorTypes.sort((a, b) => a.id > b.id ? 1 : -1).forEach(item => {
        if (item.descriptionEn === '' || item.descriptionEn === undefined || item.descriptionEn === null || item.descriptionEn === '-'){        
          itemsFSectorTypeTranslate.push(item.description)
          itemsFSectorTypeSorted.push(item)
        }
      })

      LangTranslatorService.getPostChatGptArrayTranslateIdToEn(decodeURIComponent(JSON.stringify(itemsFSectorTypeTranslate))).then(
              response => {
                try{
                  let itemsFSectorTypeTranslateResponse = decodeURIComponent(response.data.translation).replaceAll("+", " ").replaceAll("=", " ").trim()
                  let itemsFSectorTypeTranslateResponseParsed = JSON.parse(itemsFSectorTypeTranslateResponse)
                  if (response.data.translation !== null || response.data.translation !== undefined || response.data.translation !== ""){
                    itemsFSectorTypeSorted.forEach((item, index) => {
                      item.descriptionEn = itemsFSectorTypeTranslateResponseParsed[index];
                        FSectorTypeService.updateFSectorType(item).then(
                          ()=>{
                            console.log('Update Oke')
                          }
                      )
                    });
                  }
                  this.dialogLoading = false;
                  this.loading = false
                  this.snackbar = true
                  if(this.lang==='id'){
                    this.snackBarMesage = 'Data berhasil diterjemahkan'
                  }
                  if(this.lang==='en'){
                    this.snackBarMesage = 'Data successfully translated'
                  }
                  this.$refs.refConfirmDialog.setDialogState(false)
                }
                catch(error) {
                  this.dialogLoading = false;
                  this.loading = false
                  this.snackbar = true
                  if(this.lang==='id'){
                    this.snackBarMesage = 'Data yang diterjemahkan melebihi batas'
                  }
                  if(this.lang==='en'){
                    this.snackBarMesage = 'The translated data exceeds the limit'
                  }
                  this.$refs.refConfirmDialog.setDialogState(false)
                  console.log(error);
                }
              },
              error =>{
                // console.log(" Hasil Error: " + item.description)
                console.log(error.data)
              }
          )
    },

    showDialogNew() {

      this.itemSelectedIndex =-1
      const itemModified = Object.assign({}, '')
      // this.formDialogShow = true
      this.formMode = FormMode.NEW_FORM
      this.$refs.refFormDialog.showDialog(this.itemSelectedIndex, itemModified, this.itemsFDivision)
    },
    saveDataNew(itemFromRest){
      this.itemSelected = itemFromRest
      this.closeDialog()
      /**
       * Setiap data baru harus terlihat
       */
      // this.fSectorTypes.push(this.itemSelected)
      // this.fSectorTypes = [itemFromRest].concat(this.fSectorTypes)
      // this.fSectorTypes.unshift(itemFromRest)
      this.fetchFSectorType()
    },

    showDialogEdit (item) {
      this.itemSelectedIndex = this.fSectorTypesFiltered.indexOf(item)
      const itemModified = Object.assign({}, item)
      // this.formDialogShow = true
      this.formMode = FormMode.EDIT_FORM
      this.$refs.refFormDialog.showDialog(this.itemSelectedIndex, itemModified, this.itemsFDivision)
    },
    saveDataEdit(item){
      this.itemSelected = item
      if (item.lang !== 'id'){
        this.fetchFSectorType()
      }else {
        try {
          Object.assign(this.fSectorTypes[this.itemSelectedIndex], this.itemSelected)
        }catch (e) {
          this.fetchFSectorType()
          e.toString()
        }

      }
      this.closeDialog()
    },

    deleteDialogShow (item) {
      // console.log(item)
      this.itemSelectedIndex = this.fSectorTypesFiltered.indexOf(item)
      this.itemSelected = Object.assign({}, item)
      // this.myConfirmDelete = true
      this.$refs.refDeleteConfirmDialog.showDialog(this.itemSelectedIndex, item.username)
    },
    deleteDialogMultiShow(){
      if (this.multiSelect===true){
        // console.log(`nilai ${this.selectedItems}`)
        this.$refs.refDeleteConfirmDialog.showDialogMulti(this.selectedItems, `${this.selectedItems.length} items selected`)
      }
    },
    deleteItemConfirmedSingleSelect(index){
      console.log("delete SingleItem: " + index)

      const deletedItem = this.fSectorTypes[this.itemSelectedIndex]
      FSectorTypeService.deleteFSectorType(deletedItem.id).then(
        () => {
          // console.log("hapus bos " + response.data + " >> " + this.itemSelectedIndex)
          this.fSectorTypes.splice(this.itemSelectedIndex, 1)
          this.closeDialog()
        },
        error => {
          console.log(error)
          this.snackBarMesage = 'gagal hapus (digunakan oleh data anakk)'
          this.snackbar = true
          this.$refs.refDeleteConfirmDialog.setDialogState(false)
        }
      )
    },
    deleteItemConfirmedMultiSelect(items){
      // console.log(okeMessage)
      if (items.length >-1){
        let itemIds = []
        for (let i=0; i<items.length; i++){
            itemIds.push(items[i].id)
        }
        // console.log("Item idex: " + itemIds)

        FSectorTypeService.deleteAllFSectorType(itemIds).then(
            response => {
              if (response.data) {
                  for (let i = 0; i < items.length; i++) {
                    if (! response.data.includes(items[i].id)  || response.data.length === 0) {
                      const index = this.fSectorTypesFiltered.indexOf(items[i])
                      this.fSectorTypes.splice(index, 1)
                    }
                    if (response.data.length > 0) {
                      this.snackBarMesage = `${response.data.length} items gagal hapus (digunakan oleh data anak)`
                      this.snackbar = true
                    }

                    console.log("Undeleted Items: " + response.data + " = " + items[i].id)
                }
                this.closeDialog()
              }
            },
            error => {
              console.log("error " + error)
            }
        )

        // console.log(items)
        // FSectorTypeService.deleteAllFSectorTypes()
      }

    },
    closeDialog () {
      // this.myConfirmDialog = false
      this.formMode = ''
      this.$refs.refDeleteConfirmDialog.setDialogState(false)
      this.$refs.refFormDialog.setDialogState(false)

      this.$nextTick(() => {
        this.itemSelected = Object.assign({}, new FSectorType())
        this.itemSelectedIndex = -1
      })
    },

    getColorStatusActive (trueFalse) {
      if (trueFalse === true) return 'green'
      else if (trueFalse === false) return 'gray'
      else return 'gray'
    },

    lookupFDivision (fdivisionBean) {
      const str = this.itemsFDivision.filter(x => x.id===fdivisionBean)
      if (str.length>0){
        return str[0].description
      }else {
        return '-'
      }
    },


    lookupImageUrl(item){
      if (item.avatarImage===undefined || item.avatarImage===""){
        return './assets/images/no_image_available.jpeg'
      }else {
        return FileService.image_url_low(item.avatarImage)
      }
    },
    lookupImageUrlLazy(item){
      if (item.avatarImage===undefined || item.avatarImage===""){
        return './assets/images/no_image_available.jpeg'
      }else {
        return FileService.image_url_verylow(item.avatarImage)
      }
    },

  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push('/login')
    }else {
      this.fetchParent()
      this.fetchFSectorType()

    }
  }

}
</script>

<style scoped>

</style>