export default class FSectorTypeType {
  constructor(
    id,
    kode1,
    description,
    fdivisionBean,
    statusActive,
    avatarImage,
    baseId,
    lang,

    markerImage1,
    markerImage2,
    markerImage3,

    created,
    modified,
    modifiedBy
  ) {
    this.id = id;
    this.kode1 = kode1;
    this.description = description;
    this.statusActive = statusActive;
    this.fdivisionBean = fdivisionBean;
    this.avatarImage = avatarImage;
    this.baseId = baseId;
    this.lang = lang;

    this.markerImage1 = markerImage1;
    this.markerImage2 = markerImage2;
    this.markerImage3 = markerImage3;

    this.created = created;
    this.modified = modified;
    this.modifiedBy = modifiedBy;
  }

}
