<template>
  <v-card class="mt-2 ml-2 mr-2">
    <v-tabs :vertical="$vuetify.breakpoint.mdAndUp" :centered="$vuetify.breakpoint.smAndDown" color="deep-purple accent-4" background-color="#F5F5FF5F">
      <v-tab  class="justify-md-start" disabled>
        <v-icon left>
          {{ firstTabIcon }}
        </v-icon>
        {{ firstTabTitle }}
      </v-tab>

      <v-tab class="justify-start" @click="tabSectorTypeClick">
        SECTOR TYPE
      </v-tab>

      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <p>
            </p>
          </v-card-text>
        </v-card>
      </v-tab-item>

      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <FSectorTypeTable ref="refTabSectorTypeClick"/>
          </v-card-text>
        </v-card>
      </v-tab-item>


    </v-tabs>

  </v-card>
</template>

<script>
import FSectorTypeTable from "../../components/admin_potensi/jenis/FSectorTypeTable";

export default {
  name: "SectorType",
  components: {
    FSectorTypeTable
  },
  data() {
    return {
      firstTabTitle: 'SECTOR TYPE',
      firstTabIcon: ''
    }
  },
  methods: {
    tabUsersClick(){
      // this.$refs.refUsersTable.fetchParent()
    },
    tabSectorTypeClick(){
      try {
        // this.$refs.refTabSectorTypeClick.fetchParent()
      }catch (e) {
        e.toString()
      }
    },


  }
}
</script>

<style scoped>
</style>